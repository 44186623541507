import styled from 'styled-components'

export const Main = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 0 64px;

  @media (max-width: 900px) {
    flex-direction: column;
    padding: 12px 0 32px 0;
  }

  @media (max-width: 600px) {
    padding: 12px 0 24px 0;
  }
`

export const Content = styled.main`
  flex: 1;
  margin-right: 40px;

  h2 {
    font-size: 20px;
    font-weight: 500;
    margin: 32px 0 16px;

    &:first-of-type {
      margin-top: 0;
    }
  }
  
  p {
    line-height: 1.7;
  }

  ul {
    li {
      margin-bottom: 8px;
    }
  }
  `
  
export const Aside = styled.aside`
  flex: 1;
  margin-left: 40px;
  margin-bottom: 20px;

  p {
    font-size: 13px;
    opacity: 0.5;
    margin-top: 4px;
    text-align: center;
  }

  @media (max-width: 900px) {
    order: -1;
    margin-left: 0;
    max-width: 600px;
  }
`

export const MetaContainer = styled.div`
  padding: 20px 0;
  display: flex;
  justify-content: flex-end;
`