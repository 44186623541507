import React from 'react'
import { graphql } from 'gatsby'
import { FormattedMessage } from 'react-intl'
import {
  Breadcrumbs,
  Container,
  Hero,
  Page,
  ResponsiveImage,
  ShareButton,
} from '@components'
import {
  Aside,
  Content,
  Main,
  MetaContainer,
} from './style'
import { buildLocalizedPath } from '@utils/functions'

const ProjectTemplate = ({ data }) => {
  const { excerpt, fields, frontmatter, html } = data.markdownRemark
  const meta = {
    title: `Project: ${frontmatter.name}`,
    description: excerpt,
    image: require(`@assets/images/projects/${frontmatter.image}`),
    type: 'article',
  }
  return (
    <Page
      locale={fields.locale}
      meta={meta}
    >
      <Breadcrumbs
        links={[
          { link: buildLocalizedPath('/', fields.locale), name: 'header.link.home'},
          { link: buildLocalizedPath('/projects/', fields.locale), name: 'header.link.projects'},
          { link: `${buildLocalizedPath('/projects/', fields.locale)}${fields.slug}`, name: frontmatter.name},
        ]}
      />
      <Hero
        slim
        title="Project"
        subtitle={frontmatter.name}
      />
      <Container>
        <MetaContainer>
          <ShareButton
            locale={fields.locale}
            shareId={`/projekti/${fields.slug}`}
            url={`https://www.protupozarna-zastita.com/projekti/${fields.slug}`}
          />
        </MetaContainer>
        <Main>
          <Content>
            <h2>
              <FormattedMessage id="project.about" locale={fields.locale} />
            </h2>
            <div dangerouslySetInnerHTML={{ __html: html }} />
            <h2>
              <FormattedMessage id="project.year" locale={fields.locale} />
            </h2>
            <p>
              {frontmatter.year}
            </p>
            <h2>
              <FormattedMessage id="project.work" locale={fields.locale} />
            </h2>
            <ul>
              {
                frontmatter.work.map((w, i) => (
                  <li key={`rad-${i + 1}`}>
                    {w}
                  </li>
                ))
              }
            </ul>
          </Content>
          <Aside>
            <ResponsiveImage
              alt={`Projekt - ${frontmatter.name}`}
              filename={`projects/${frontmatter.thumb}`}
            />
            {
              frontmatter.image_copy &&
              <p>
                {frontmatter.image_copy}
              </p>
            }
          </Aside>
        </Main>
      </Container>
    </Page>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id } ) {
      excerpt
      fields {
        slug
        locale
      }
      html
      frontmatter {
        description
        image
        name
        thumb
        work
        year
      }
    }
  }
`

export default ProjectTemplate
